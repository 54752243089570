.neomorphe-input,
._2xbD- {
  margin: 0px;
}

._U6nBC {
  height: 50px;
  font-size: 12px;
  font-weight: 500;
  color: #6e847e;
  line-height: 18px;
}
