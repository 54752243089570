.neomorphe-input,
._2xbD- {
  margin: 0px;
}

._U6nBC {
  /* width: 300px; */
  height: 50px;
  font-size: 12px;
  font-weight: 500;
  color: #6e847e;
  line-height: 18px;
}

.PhoneInputCountry {
  margin-top: 10px;
}

.PhoneInputInput {
  background: transparent;
  margin-top: 10px;
  border-color: transparent !important;
}

textarea:focus,
input:focus {
  outline: none;
}
